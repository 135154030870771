// @import "app/variables";

$gray-dark: $brand-primary;

.topnavbar .nav-wrapper {
  background-color: #fff;
  background-image: none;
}
.nav-fonts .fa {
  color: #263746;
}
.navbar-header {
  // padding-top: 3px;
  padding-top: 0;
}
.navbar-nav > li > a {
  // padding: 15px 5px;
  padding: 17px 22px;
}
.navbar-nav > li.nav-icon {
  border-left: 1px solid #929292;
}
.nav-account_name > a {
  color: #565956;
  margin: 5px 5px 0 0;
  padding-right: 12px !important;
  border-right: 2px solid #929292;
}
.navbar-nav.navbar-right {
  .dropdown-menu li a:hover,
  li a:hover,
  li.open a.dropdown-toggle {
    background: $gray-dark;
    color: #fff;
    em {
      color: #fff;
    }
  }
}
.nav-help > a {
  color: $gray-dark;
  // margin-top: 5px;
}
.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
  color: #051d49 !important;
}
@media only screen and (max-width: 767px) {
  .topnavbar .navbar-header .navbar-brand {
    max-width: 180px;
  }
  .nav-fonts {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 50%;
  }
  .sidebar-toggle {
    padding-top: 12px;
    right: -8px;
  }
  .navbar-right {
    position: absolute;
    top: 0;
    z-index: 99;
    right: 0  ;
  }
  .topnavbar .navbar-header {
    background: #fff;
  }
  .navbar-right.navbar-nav > li > a {
    padding: 12px 15px;
  }
  .top-btn {
    background: transparent;
    border: none;
  }
  .navbar-nav > li > a {
    padding: 17px 10px;
  }
}
