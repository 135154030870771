//== Bootstrap
// @import "../bootstrap.scss";
// Bootstrap
@import "./bootstrap-cobra.scss";
// // Override bootstrap variables
@import "./variables-cobra";

// Bootstrap
@import "../../bootstrap/bootstrap/_mixins";

// Global definition of media queries
@import "../../app/media-queries";
// Utilities
@import "../../app/utils-definitions";

// Bootstrap reset
@import "../../app/bootstrap-reset";

// Typography
@import "../../app/typo";

// Layout
@import "../../app/layout";
@import "../../app/layout-extra";
@import "../../app/layout-animation";
@import "../../app/top-navbar";
@import "../../app/sidebar";

// Components
// @import "app/preloader";
@import "../../app/breadcrumbs";
@import "../../app/loading-bar";
@import "../../app/animate";
@import "../../app/button-extra";
@import "../../app/placeholder";
@import "../../app/panels";
@import "../../app/progress-extra";
@import "../../app/radial-bar";
@import "../../app/circles";
@import "../../app/timeline";
@import "../../app/dropdown-extra";
@import "../../app/row-extra";
@import "../../app/half-float";
@import "../../app/masonry-grid";
@import "../../app/widget";
@import "../../app/typeahead";
@import "../../app/table-grid";
@import "../../app/file-upload";

@import "../../app/chart-flot";
@import "../../app/chart-easypie";
@import "../../app/form-elements";

@import "../../app/summernote";

// Tables
@import "../../app/table-extras";
// Plugins
@import "../../app/slim-scroll";
@import "../../app/spinner";
@import "../../app/vector-map";

// Utilities
@import "../../app/utils";

// Print CSS
@import "../../app/print";

// Settings
@import "../../app/settings";
// Flex Box Grid
@import "../../app/flexboxgrid";

// Header
@import "../../app/header";

@import "../../app/sidebar-mo";

//== Application
// @import "../app.scss";

/** Auth0 styles for login screen */
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 35px !important;
}
.auth0-lock.auth0-lock .auth0-lock-overlay {
  background: none !important;
}
.auth0-lock.auth0-lock .auth0-lock-header {
  padding: 21px 11px 11px 11px !important;
  height: 68px !important;
}
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-header-bg .auth0-lock-header-bg-blur {
  opacity: 0 !important;
}
@media (min-width: 481px) {
  .auth0-lock.auth0-lock .auth0-lock-header-bg {
    background: #fff !important;
  }
}

.automation-chart .panel-body {
  height: 148px;
  stacked-bar-chart {
    height: 120px !important;
    div {
      height: 120px !important;
    }
  }
}

.client-chart .panel-body {
  height: 148px;
}
.client-chart .panel-body .bar-chart {
  height: 120px !important;
}
.table th a.sorting i {
  position: relative;
  right: -5px;
  top: 0px;
  color: #888;
}
.template-legend-modal {
  width: 800px;
  margin: 30px auto;
  .content-heading {
    padding: 17px;
  }
  .content-data {
    margin-bottom: 30px;
  }
}
.campaigns-checkboxes-list {
  margin-top: 20px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.bg-widget-class {
  background: #fff;
  color: $brand-primary;
  border: 1px solid #cfdbe2;
  border-top-width: 3px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  a {
    color: $brand-primary !important;
  }
}
/** Nav buttons styling */
.content-heading {
  .btn-group {
    padding-left: 10px;
  }
  .btn {
    border-radius: 5px;
    border-color: $brand-primary-light;
  }
  .btn-primary {
    color: $brand-primary-light;
    background-color: #fff;
    margin-left: 10px;
  }
  .btn-primary:hover {
    color: $brand-primary-light;
    background-color: #fff;
    border-color: $brand-primary-light;
  }
  .btn-primary:active,
  .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: $brand-primary-light;
    background-color: #fff;
    border-color: $brand-primary-light;
  }
  .btn-primary:active:hover,
  .btn-primary:active:focus,
  .btn-primary:active.focus,
  .btn-primary.active:hover,
  .btn-primary.active:focus,
  .btn-primary.active.focus,
  .open > .btn-primary.dropdown-toggle:hover,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle.focus {
    color: #fff;
    background-color: $brand-primary-light;
    border-color: $brand-primary-light;
  }
}
/** actions buttons styling */
form,
.action-button,
.content-heading {
  .btn-info {
    color: #fff;
    background-color: $brand-primary-light !important;
    border-color: $brand-primary-light;
    margin-left: 10px;
  }

  .btn-info.disabled:hover,
  .btn-info.disabled:focus,
  .btn-info.disabled.focus,
  .btn-info[disabled]:hover,
  .btn-info[disabled]:focus,
  .btn-info[disabled].focus,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus {
    background-color: $brand-primary-light;
    border-color: $brand-primary-light;
  }
  .btn-info:hover {
    color: #fff;
    background-color: $brand-primary-light;
    border-color: $brand-primary-light;
  }
}
table a,
.hyperlink-color a {
  color: $link-color !important;
}
/** Actions Nav Styling */
table,
.actions-nav {
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #fff !important;
    background-color: $brand-primary;
  }
  .dropdown-menu > li > a {
    color: #263746 !important;
  }
}
/** Disable div elements */
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
  fieldset {
    .form-group {
      opacity: 0.5;
    }
  }
}
.panel-body .table-responsive {
  overflow-x: inherit !important;
}
.filters-view {
  padding-left: 16px;
  padding-top: 6px;
  a {
    margin-right: 8px;
  }
}
.refresh-listing-icon {
  cursor: pointer;
}
.refresh-listing-top-icon {
  margin-right: 12px;
  cursor: pointer;
}
.breadcrumb .back-link {
  color: #929292;
  text-decoration: underline;
  cursor: pointer;
}
@media only screen and (min-width: 1601px) {
  bar-chart canvas {
    height: 100% !important;
  }
}
/*Close Icon for modal box*/
.modalcloseicon {
  margin-right: 10px;
  cursor: pointer;
  cursor: hand;
}
//For modal box heading
.modal-dialog .content-heading {
  padding: 17px;
}

.minsize {
  min-width: 100%;
  min-height: 100px;
}
label.required:after {
  content: " *";
  color: red;
}
.greyout {
  color: lightgrey;
}
.ml-12 {
  margin-left: 12px !important;
}
.mr-6 {
  margin-right: 6px !important;
}
.theme-green .bs-datepicker-head,
.theme-green .bs-datepicker-body table td span.selected {
  background-color: $brand-primary !important;
}

.toast-top-right {
  top: 60px !important;
}